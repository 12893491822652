#root {
  /* background-image: url(./assets/images/youtube-logo.jpeg);
  width: 100vw;
  height: 100vh;
  background-size: cover; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  transition-duration: 0.4s;
}

button:hover {
  background-color: yellow; /* Green */
  color: gray;
  border-radius: 5px;
  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.error-txt {
  color: yellow;
}

.container {
  width: 50vw;
  min-width: 500px;
  height: 100%;
  margin: 0 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.request-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: solid thin gray;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10vh;
  color: #001bff;
  background: #dedede;
  opacity: 0.9;
  background-image: linear-gradient(to right, red, black);
}

.info-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: solid thin gray;
  border-radius: 5px;
  margin-top: 5px;
}

.request-form-inputs {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-field {
  margin: 10px;
  margin-left: 20px;
}

.channel-input {
  width: 250px;
}

.channel-btn {
  margin-right: 20px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background: red;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: larger;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
  border: solid thin gray;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 1px;
  background-color: #e5e5e5;
  color: black;
  cursor: pointer;
}

li.disabled {
  color: gray;
  cursor: not-allowed;
}

li.selected {
  color: blue;
}

.info-header, .info-footer {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 20px;
  color: #001bff;
  background: #dedede;
  opacity: 0.9;
  min-height: 50px;
  align-items: center;
}

.info-header {
}

.info-body {
  border-top: solid thin gray;
  border-bottom: solid thin gray;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 400px;
  background: white;
  opacity: 0.9;
  max-height: 500px;
  overflow-y: scroll;
}

tr {
  height: 30px;
}

td.title {
  width: 150px;
  font-weight: bold;
}

td.title.description {
  display: inline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
